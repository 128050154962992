import React from "react"
import { Container } from "../common/elements"
import useContent from "../hooks/useContent"
import { BannerWrapper } from "../page-components/home/sections/elements"

export const PromotionalBanner = () => {
  const { content } = useContent("promotionalBanner", true)
  return (
    <BannerWrapper>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: content("title") }} />
      </Container>
    </BannerWrapper>
  )
}
